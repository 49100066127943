import React from 'react';
import './profileDetail.scss';
import { connect } from 'react-redux';
import { updateUserData } from '../../redux/action/user'
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import ProfileSection from '../../component/profileSection';

const ProfileDetail = (props) => {
    return (
        <div className="login-container">
            <div className="flex-center">
                <div className='out-box-wrap'>
                    <Card sx={{ display: 'flex', borderRadius: '15px', backgroundColor: 'transparent' }}>
                        <Box sx={{ width: '100%' }}>
                            <ProfileSection />
                        </Box>
                    </Card>
                </div>
            </div>
        </div>
    );
};

ProfileDetail.propTypes = {};
ProfileDetail.defaultProps = {};

const actionCreators = {
    updateUserData
};

export default connect(
    ({ user }) => ({
        user,
    }),
    actionCreators
)(ProfileDetail);
