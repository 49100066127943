import React from "react";
import { connect } from "react-redux";
import { getSurveyList, getUserData } from "../../redux/action/user";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import HistoryBody from "../../component/history/historyBody";
import { Container } from "@mui/material";
import CenterLoading from "../../component/core/CenterLoading";

const History = (props) => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    props.getSurveyList();
    props.getUserData(localStorage.getItem("userKey"), navigate, location.pathname);
    localStorage.removeItem("review");
  }, []);

  return (
    <>
      <Container>
        {props?.user?.isLoading ? (
          <CenterLoading open={props?.user?.isLoading} />
        ) : (
          <HistoryBody />
        )}
      </Container>
    </>
  );
};

History.propTypes = {};
History.defaultProps = {};

const actionCreators = {
  getSurveyList,
  getUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(History);
