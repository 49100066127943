import React, { useEffect } from "react";
import Header from "../../component/header/index"; // Adjust the import according to your file structure
import "./plans.scss"; // Import a CSS file for styling
import { useNavigate } from "react-router";
import { Container, Typography, Button, Paper } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import { getSurveyList, setUserData } from "../../redux/action/user";

const PaymentSuccess = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const list = props.user.surveyIdList;
  const selectedSurvey = list && list.length > 0 && list[list.length - 1];

  useEffect(() => {
    if (!localStorage.getItem("userKey")) {
      navigate("/signin");
    }
    props.getSurveyList();
  }, []);

  const handleRedirect = () => {
    if (selectedSurvey && selectedSurvey.s_IsSurveyCompleted === 1) {
      localStorage.setItem("progressDone", true);
    } else {
      localStorage.removeItem("progressDone");
      localStorage.removeItem("detailDone");
    }
    if (
      selectedSurvey.Height &&
      selectedSurvey.Weight &&
      selectedSurvey.ZipCode &&
      selectedSurvey.Age &&
      selectedSurvey.Gender
    ) {
      if (selectedSurvey.Score === 0) {
        localStorage.removeItem("detailDone");
      } else {
        localStorage.setItem("detailDone", true);
      }
    } else {
      localStorage.removeItem("detailDone");
    }

    if (localStorage.getItem("progressDone") === null) {
      navigate(`/progress`);
    } else if (localStorage.getItem("detailDone") === null) {
      navigate("/profiledetail");
    } else {
      navigate(`/result`);
    }
    localStorage.setItem("assesmentKey", selectedSurvey.SurveyId);
    dispatch(props.setUserData({ surveyId: selectedSurvey.SurveyId }));
  };

  return (
    <>
      {props.user.isLoading ? (
        "Loading..."
      ) : (
        <Container maxWidth="sm" style={{ marginTop: "20px", height: '72vh' }}>
          <Paper elevation={3} style={{ padding: "20px", textAlign: "center" }}>
            <Typography variant="h4" color="success.main">
              Payment Successful!
            </Typography>
            <Typography variant="body1" style={{ margin: "20px 0" }}>
              Thank you for your payment. Your transaction has been processed
              successfully.
            </Typography>

            {/* <Typography variant="h6">Order Details</Typography>
          <Typography variant="body2"><strong>Order ID:</strong> {orderId}</Typography>
          <Typography variant="body2"><strong>Amount:</strong> ${amount}</Typography> */}

            <Button
              variant="contained"
              color="primary"
              onClick={() => handleRedirect()}
              style={{ marginTop: "20px" }}
            >
              {selectedSurvey && selectedSurvey.s_IsSurveyCompleted === 1
                ? "See my goals"
                : "Continue Assessment"}
            </Button>
          </Paper>
        </Container>
      )}
    </>
  );
};

const actionCreators = {
  getSurveyList,
  setUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(PaymentSuccess);
