import { Box, Button, Modal, Typography } from "@mui/material";
import React, { Fragment, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { setUserData, updateFeedback } from "../redux/action/user";

const ExperienceRatingModal = (props) => {
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [rating, setRating] = useState("");

  const isSubscription =
    props.user &&
    props.user.subscription &&
    props.user.subscription.isSubscription;

  // useEffect(() => {
  //   setModalOpen(props.user.isLoggedinModal);
  // }, [props.user.isLoggedinModal]);

  useEffect(() => {
    // Get the current date in YYYY-MM-DD format
    const currentDate = new Date().toISOString().split("T")[0];

    // Retrieve the stored date of when the modal was last shown
    const lastShownDate = localStorage.getItem("lastShownDate");
    console.log("lastShownDate", lastShownDate, currentDate)
    if (isSubscription) {
      // Check if it's a new user (i.e., no date is stored yet)
      if (!lastShownDate) {
        // Show the modal on the first day of the user's registration
        setModalOpen(true);
        // Store the current date as the last shown date
        localStorage.setItem("lastShownDate", currentDate);
      } else if (lastShownDate !== currentDate) {
        // If the stored date doesn't match today's date, show the modal
        setModalOpen(true);
      }

      // Set the modal open state based on the logged-in status (props.user.isLoggedinModal)
      setModalOpen(props.user.isLoggedinModal);
    }
  }, [localStorage.getItem("lastShownDate")]);

  // Handle modal close
  const handleClose = async (val) => {
    if (val) {
      const data = {
        UserID: localStorage.getItem("userKey"),
        questionId: 1,
        feedbackText: val,
        // rating: 5,
      };
      await props.updateFeedback(data);
      // Save the current date in localStorage to track the last shown date
      const currentDate = new Date().toISOString().split("T")[0];
      localStorage.setItem("lastShownDate", currentDate);
      dispatch(setUserData({ isLoggedinModal: false }));
      setRating(val);
      setModalOpen(false); // Close the modal once all steps are completed
    }
  };

  return (
    <Fragment>
      <Modal open={modalOpen}>
        <Box
          className="center-loading"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            height: "auto",
            maxWidth: "80%",
            bgcolor: "#fff",
            border: "2px solid #000",
            boxShadow: 24,
            borderRadius: "10px",
            p: 4,
            textAlign: "center",
          }}
        >
          <Box className="center-content">
            <Box>
              <Typography variant="h6">How was your experience?</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-around", // Distribute items evenly across the row
                  alignItems: "center",
                  width: "100%",
                  mt: 3,
                }}
              >
                {/* Better Rating */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mr: 1,
                  }}
                >
                  <Box sx={{ fontSize: "50px" }}>😄</Box>
                  <Button
                    variant="outlined"
                    onClick={() => handleClose("Better")}
                    sx={{ marginTop: 1 }}
                  >
                    Better
                  </Button>
                </Box>

                {/* Same Rating */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    mr: 1,
                  }}
                >
                  <Box style={{ fontSize: "50px" }}>😐</Box>
                  <Button
                    variant="outlined"
                    onClick={() => handleClose("Same")}
                    sx={{ marginTop: 1 }}
                  >
                    Same
                  </Button>
                </Box>

                {/* Worse Rating */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Box style={{ fontSize: "50px" }}>😞</Box>
                  <Button
                    variant="outlined"
                    onClick={() => handleClose("Worse")}
                    sx={{ marginTop: 1 }}
                  >
                    Worse
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Fragment>
  );
};

ExperienceRatingModal.propTypes = {};
ExperienceRatingModal.defaultProps = {};

const actionCreators = {
  updateFeedback,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(ExperienceRatingModal);
