import { Alert, Snackbar } from '@mui/material';
import React from 'react';
import './snackBar.scss';

const SnackBar = (props) => {
    return (
        <>
            {props.handleCloseSnackBar && props.message && (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    open={props.open}
                    autoHideDuration={5000}
                    onClose={() => props.handleCloseSnackBar()}
                >
                    <Alert
                        onClose={() => props.handleCloseSnackBar()}
                        severity={props?.severity || "error"}
                        variant="filled"
                        sx={{
                            width: "100%",
                            maxWidth: "400px",
                            wordWrap: "break-word",
                            whiteSpace: "pre-line",
                        }}
                    >
                        {props.message}
                    </Alert>
                </Snackbar>
            )}

        </>
    );
};

SnackBar.propTypes = {};
SnackBar.defaultProps = {};

export default SnackBar;
