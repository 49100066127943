import axios from "axios";
import { SET_USER_DATA } from "../../constants/ActionTypes";
import api from "../../constants/config";

export function setUserData(content) {
  return {
    type: SET_USER_DATA,
    content,
  };
}

export function updateUserData(obj) {
  return (dispatch) => {
    dispatch(setUserData(obj));
  };
}

export function getQuestionList() {
  return (dispatch, getState) => {
    const { user } = getState();

    if (
      localStorage.getItem("userKey") === "" &&
      localStorage.getItem("assesmentKey") === ""
    ) {
      return;
    }

    dispatch(setUserData({ questionError: "" }));
    api.post("question", {
      userId: localStorage.getItem("userKey"),
      surveyId: localStorage.getItem("assesmentKey"),
    })
      .then((res) => {
        if (res.status === 200) {
          let countDeterminArray = [];
          res.data.map((obj, index) => {
            if (
              countDeterminArray.filter(
                (subObj) => subObj.name === obj.Determinant
              ).length === 0
            ) {
              countDeterminArray.push({
                name: obj.Determinant,
                isFillUp: false,
              });
            }
            return "";
          });

          dispatch(
            setUserData({
              questionList: res.data,
              totalDetermine: countDeterminArray,
              isNewUser: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setUserData({
            questionError: "Some Error Please refresh page or check Data",
          })
        );
      });
  };
}

export function signUpUser(object) {
  return (dispatch) => {
    localStorage.removeItem("progressDone");
    localStorage.removeItem("detailDone");
    dispatch(setUserData({ registrationError: "", isLoading: true }));
    let bodyData = {
      firstName: object.firstName,
      lastName: object.lastName,
      emailaddress: object.emailaddress,
      mobileNumber: object.mobileNumber,
      countryCode: object.countryCode,
      hostName: object.hostName,
      // marketingInfo: object.marketingInfo,
    };
    api.post("register", bodyData)
      .then((res) => {
        if (res.status === 200) {
          const tempObj = {
            hash: res.data.hash,
          };
          dispatch(
            setUserData({ ...tempObj, status: "otp", isLoading: false })
          );
        } else {
          dispatch(
            setUserData({
              registrationError: res.data.message,
              isLoading: false,
            })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setUserData({
            registrationError: "Some Error Please refresh page or check Data",
            isLoading: false,
          })
        );
      });
  };
}

export function updateUser(object, openModal) {
  return (dispatch) => {
    dispatch(setUserData({ profileError: "", isLoading: true }));
    api.post("profile", object)
      .then((res) => {
        if (res.status === 200) {
          dispatch(setUserData({ ...res.data, isLoading: false }));
          if (openModal) {
            openModal();
          }
        } else {
          dispatch(
            setUserData({ profileError: res.data.message, isLoading: false })
          );
        }
      })
      .catch((err) => {
        console.log("error", err);
        dispatch(
          setUserData({
            profileError: "Some Error Please refresh page or check Data",
            isLoading: false,
          })
        );
      });
  };
}

export function updateQuestionAnswer(object) {
  return (dispatch, getState) => {
    dispatch(setUserData({ answeredData: "" }));
    api.post("question/userdatasave", object)
      .then((res) => {
        if (res.status === 200) {
          let { user } = getState();
          dispatch(
            setUserData({
              answeredData: user.answeredData ? user.answeredData + 1 : 1,
            })
          );
        } else {
          dispatch(setUserData({ questionError: res.data.message }));
        }
      })
      .catch((err) => {
        dispatch(
          setUserData({
            questionError: "Some Error Please refresh page or check Data",
          })
        );
      });
  };
}

export function loginUser(object) {
  return (dispatch) => {
    dispatch(setUserData({ loginError: "", isLoading: true }));
    api.post("login", object)
      .then((res) => {
        if (res.status === 200) {
          const tempObj = {
            hash: res.data.hash,
          };
          dispatch(
            setUserData({ ...tempObj, /* status: 'otp', */ isLoading: false })
          );
        } else {
          dispatch(
            setUserData({ loginError: res.data?.message, isLoading: false })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setUserData({
            loginError: err?.message || "Something went wrong",
            isLoading: false,
          })
        );
      });
  };
}

export function verifyOTP(object, navigate) {
  return (dispatch) => {
    dispatch(setUserData({ otpError: "", isLoading: true }));
    api.post("verifyOtp", object)
      .then((res) => {
        if (res.status === 200) {
          if (res.data[0]) {
            localStorage.setItem("userKey", res.data[0].UserID);
            localStorage.setItem("assesmentKey", res.data[0].SurveyId);
            localStorage.setItem("fingerprint", res.data[0].fingerprint);
            let demo = {
              firstName: res.data[0].Fname,
              lastName: res.data[0].Lname,
              hostName: res.data[0].source,
              emailaddress: res.data[0].EmailID,
              // marketingInfo: res.data[0].marketingInfo,
              mobileNumber: res.data[0].MobileNo,
              countryCode: res.data[0].CountryCode,
              height: res.data[0].Height,
              heightunit: res.data[0].HeightUnit
                ? res.data[0].HeightUnit
                : "Inches",
              weight: res.data[0].Weight,
              weightunit: res.data[0].WeightUnit
                ? res.data[0].WeightUnit
                : "Pounds",
              zipCode: res.data[0].ZipCode,
              age: res.data[0].Age,
              gender: res.data[0].Gender,
              _id: res.data[0].UserID,
              surveyId: res.data[0].SurveyId,
              signupForm: {
                firstName: "",
                lastName: "",
                emailaddress: "",
                mobileNumber: "",
                countryCode: "+1",
                hostName: "",
                // marketingInfo: ''
              },
              loginForm: {
                emailaddress: "",
                countryCode: "+1",
                mobileNumber: "",
              },
              otpSuccess: true,
              isLoading: false,
              isLoggedinModal: res.data[0].dayFirstLogin,
            };
            dispatch(setUserData(demo));

            // console.log("res.data[0].isSurveyCompleted",res.data[0].isSurveyCompleted)

            if (res.data[0].isSurveyCompleted === 1) {
              localStorage.setItem("progressDone", true);
            }

            if (
              demo.height &&
              demo.weight &&
              demo.zipCode &&
              demo.age &&
              demo.gender
            ) {
              localStorage.setItem("detailDone", true);
            }

            if (localStorage.getItem("progressDone") === null) {
              navigate(`/progress`);
            } else if (localStorage.getItem("detailDone") === null) {
              navigate("/profiledetail");
            } else {
              navigate(`/result`);
            }
          }
        } else {
          dispatch(
            setUserData({ otpError: res.data.message, isLoading: false })
          );
        }
      })
      .catch((err) => {
        if (err?.response?.data?.message) {
          dispatch(
            setUserData({
              otpError: err.response.data.message,
              isLoading: false,
            })
          );
        } else {
          dispatch(
            setUserData({
              otpError: "Some Error Please refresh page or check Data",
              isLoading: false,
            })
          );
        }
      });
  };
}

export function getUserData(userKey, navigate, pathname) {
  return (dispatch) => {
    dispatch(setUserData({ userData: "", isLoading: true }));
    api.post("userdata", { id: userKey })
      .then((res) => {
        if (res.status === 200) {
          if (res.data[0]) {
            localStorage.setItem("userKey", res.data[0].UserID);
            let demo = {
              firstName: res.data[0].Fname,
              lastName: res.data[0].Lname,
              emailaddress: res.data[0].EmailID,
              mobileNumber: res.data[0].MobileNo,
              countryCode: res.data[0].CountryCode,
              hostName: res.data[0].source,
              // marketingInfo: res.data[0].marketingInfo,
              height: res.data[0].Height,
              heightunit: res.data[0].HeightUnit
                ? res.data[0].HeightUnit
                : "Inches",
              weight: res.data[0].Weight,
              weightunit: res.data[0].WeightUnit
                ? res.data[0].WeightUnit
                : "Pounds",
              zipCode: res.data[0].ZipCode,
              age: res.data[0].Age,
              gender: res.data[0].Gender,
              _id: res.data[0].UserID,
              subscription: res.data[0].subscription,
              programPurchased: res.data[0].programPurchased,
              customerId: res.data[0].customerId,
            };

            // if (res.data[0].isSurveyCompleted === '1') {
            // 	localStorage.setItem('progressDone', true)
            // }

            // if (
            //   demo.height &&
            //   demo.weight &&
            //   demo.zipCode &&
            //   demo.age &&
            //   demo.gender
            // ) {
            //   localStorage.setItem("detailDone", true);
            // }

            dispatch(setUserData(demo));

            if (pathname && pathname !== "/result") {
              navigate(pathname);
            } else if (
              localStorage.getItem("progressDone") == null
            ) {
              navigate(`/progress`);
            } else if (
              localStorage.getItem("detailDone") == null
            ) {
              navigate("/profiledetail");
            } else {
              navigate(`/result`);
            }
          }
        } else {
          dispatch(
            setUserData({ userData: res.data.message, isLoading: false })
          );
        }
      })
      .catch((err) => {
        dispatch(
          setUserData({
            userData: "Some Error Please refresh page or check Data",
            isLoading: false,
          })
        );
      });
  };
}

export function getDeterminants() {
  return (dispatch) => {
    dispatch(
      setUserData({
        isLoading: true,
      })
    );
    return api.post("userscore", {
      userId: localStorage.getItem("userKey"),
      surveyId: localStorage.getItem("assesmentKey"),
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getSurveyList());
          dispatch(
            setUserData({
              determinantList: res.data.data,
              userFinalScore: res.data.finalScore,
              isLoading: false,
            })
          );
          return res?.data?.data
        }
      })
      .catch((err) => {
        dispatch(
          setUserData({
            isLoading: false,
          })
        );
      });
  };
}

export function updateSuggestion(userId, suggestionId) {
  return (dispatch) => {
    api.post("updateSuggestionDate", {
      userId: userId,
      SuggestionID: suggestionId,
    })
      .then((res) => { })
      .catch((err) => {
      });
  };
}

export function updateReference(userId, suggestionId) {
  return (dispatch) => {
    api.post("updateReferenceDate", {
      userId: userId,
      SuggestionID: suggestionId,
    })
      .then((res) => { })
      .catch((err) => {
      });
  };
}

export function deleteUserResponse(userId, surveyId, navigate) {
  return (dispatch) => {
    dispatch(
      setUserData({
        isLoading: true,
      })
    );
    api.post("question/deletedatasave", {
      userId: userId,
      surveyId: surveyId,
    })
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("progressDone");
          localStorage.removeItem("detailDone");
          dispatch(
            setUserData({
              questionList: [],
              totalDetermine: [],
              determinantList: [],
              height: "",
              heightunit: "Inches",
              weight: "",
              weightunit: "Pounds",
              zipCode: "",
              age: "",
              gender: "",
              isLoading: false,
              otpSuccess: false,
              userFinalScore: 0,
              isNewUser: false,
            })
          );
          navigate(`/progress`);
        }
      })
      .catch((err) => {
        dispatch(
          setUserData({
            isLoading: false,
          })
        );
      });
  };
}

export function newTest(userId, navigate) {
  return (dispatch) => {
    dispatch(
      setUserData({
        isLoading: true,
      })
    );
    api.post("/newsurvey", { userId: localStorage.getItem("userKey") })
      .then((res) => {
        if (res.status === 200) {
          localStorage.removeItem("progressDone");
          localStorage.removeItem("detailDone");
          localStorage.setItem("assesmentKey", res.data.surveyId);
          dispatch(
            setUserData({
              questionList: [],
              totalDetermine: [],
              determinantList: [],
              isLoading: false,
              userFinalScore: 0,
              isNewUser: false,
              surveyId: res.data.surveyId,
            })
          );
          navigate(`/progress`);
        }
      })
      .catch((err) => {
        dispatch(
          setUserData({
            isLoading: false,
          })
        );
      });
  };
}

export function getSurveyList() {
  return (dispatch) => {
    dispatch(setUserData({ isLoading: true }));
    api.post("surveyIdList", {
      userId: localStorage.getItem("userKey"),
    })
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            setUserData({
              ...res.data,
              surveyIdList: res.data.surveyIdList,
              isLoading: false,
            })
          );
        } else {
          dispatch(setUserData({ isLoading: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserData({ isLoading: false }));
      });
  };
}


export const paymentCheckoutURLApi = async (data) => {
  const res = await api.post("create-pay-checkout", data)
  return res
}

export const getProgramListApi = async () => {
  const res = await api.get("get-program-subscription-list-user")
  return res
}

export const getPaymentHistoryListApi = async () => {
  const res = await api.get("getUsersOrder")
  return res
}


export function initiateGoals(data) {
  return async (dispatch) => {
    dispatch(setUserData({ isLoading: true }));
    try {
      const res = await api.post("save-goals", data);
      if (res.status === 200) {
        dispatch(
          setUserData({
            ...res.data,
            isLoading: false,
          })
        );
      } else {
        dispatch(setUserData({ isLoading: false }));
      }
      return res.status;
    } catch (err) {
      dispatch(
        setUserData({
          isLoading: false,
          error: { errormsg: err.response.data.message },
        })
      );
      return err.response.status;
    }
  };
}

export function withdrawGoals(data) {
  return async (dispatch) => {
    dispatch(setUserData({ isLoading: true }));
    try {
      const res = await api.post("withdraw-goal", data);
      if (res.status === 200) {
        dispatch(
          setUserData({
            ...res.data,
            isLoading: false,
          })
        );
      } else {
        dispatch(setUserData({ isLoading: false }));
      }
      return res.status;
    } catch (err) {
      dispatch(
        setUserData({
          isLoading: false,
          error: { errormsg: err.response.data.message },
        })
      );
      return err.response.status;
    }
  };
}

export function getFeedbackQuestionList() {
  return (dispatch) => {
    dispatch(
      setUserData({
        isLoading: true,
      })
    );
    api.get("feedback-questions")
      .then((res) => {
        if (res.status === 200) {
          const response = res?.data?.data || [];
          if (response) {
            dispatch(
              setUserData({
                feedbackQuestionList: response,
                isLoading: false,
              })
            );
          }
        }
      })
      .catch((err) => {
        dispatch(
          setUserData({
            isLoading: false,
            error: { errormsg: err.message },
          })
        );
      });
  };
}

export function updateFeedback(data) {
  return (dispatch) => {
    dispatch(setUserData({ isLoading: true }));
    api.post("submit-feedback", data)
      .then((res) => {
        if (res.status === 200) {
          dispatch(
            setUserData({
              isLoading: false,
            })
          );
        } else {
          dispatch(setUserData({ isLoading: false }));
        }
      })
      .catch((err) => {
        dispatch(setUserData({ isLoading: false }));
      });
  };
}


export const getCoursesApi = async () => {
  const res = await api.get('getCourses');
  return res?.data;
}

export const cancelSubscriptionApi = async (paymentHistoryId) => {
  const res = await api.get(`cancel_subscription/${paymentHistoryId}`);
  return res;
}

export const sendInviteForLabsApi = async () => {
  const res = await api.post('sendInviteForLabs');
  return res;
}