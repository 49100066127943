import axios from "axios"


// import io from 'socket.io-client';

// // Connect to the server (Replace 'http://localhost:3000' with your server URL)
// export const socket = io('http://localhost:7000/');


// export const api_key = 'http://172.16.1.106:7000/api/v1/'
// export const api_key = 'https://reasonably-sound-tortoise.ngrok-free.app/api/v1/'
export const api_key = 'https://devapi.japa.health/api/v1/'
// export const api_key = "http://localhost:7000/api/v1/";
// export const sns_api_key = 'http://172.16.1.106:7000/api/v1/'


const api = axios.create({
    baseURL: api_key,
    headers: {
        'Content-Type': 'application/json',
    },
})

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('userKey');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
            config.headers['authorization-user'] = `Bearer ${localStorage.getItem('fingerprint')}`;
        }
        return config
    },
    (error) => {
        return Promise.reject(error);
    }
)
api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response) {
            // Handle 401 (Unauthorized) - Redirect to login
            if (error.response.status === 401) {
                console.error('Unauthorized, redirecting to login...');
                localStorage.clear();
                window.location.href = '/signin';
                window.location.reload();
            }
            // Handle other status codes globally
            return Promise.reject(error.response.data);
        }
        return Promise.reject(error);
    }
)

export default api;