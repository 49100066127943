import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Button, Container, Modal, Typography } from "@mui/material";
import CenterLoading from "../../component/core/CenterLoading";
import { cancelSubscriptionApi, getPaymentHistoryListApi, getUserData } from "../../redux/action/user";
import SnackBar from "../../atoms/snackBar";
import CloseIcon from "@mui/icons-material/Close";



const PaymentHistory = (props) => {
  const [loading, setLoading] = useState(false);
  const [paymentHistoryList, setPaymentHistoryList] = useState([]);
  const [subscriptionCancelModal, setSubscriptionCancelModal] = useState(false)
  const [paymentHistoryId, setPaymentHistoryId] = useState('');
  const [openSnackBar, setOpenSnackBar] = useState({
    open: false,
    message: '',
    severity: ''
  })


  const getPaymentHistoryList = async () => {
    setLoading(true)
    try {
      const res = await getPaymentHistoryListApi();
      setPaymentHistoryList(res.data?.data)
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    getPaymentHistoryList()
  }, []);

  const handleCancelSubscription = async () => {
    try {
      const res = await cancelSubscriptionApi(paymentHistoryId);
      setOpenSnackBar({
        open: true,
        message: res?.data?.message,
        severity: 'success'
      })

      // await getProgramList();
      await getPaymentHistoryList()
      await props.getUserData(localStorage.getItem("userKey"));

    } catch (error) {
      setOpenSnackBar({
        open: true,
        message: error?.message,
        severity: 'error'
      })
    } finally {
      setSubscriptionCancelModal(false);
      setPaymentHistoryId('');
    }
  };


  const isPlanActive = (startDate, endDate, orderStatus) => {
    if (endDate === null) {
      return "Life Time Access";
    }

    const start = startDate && new Date(startDate);
    const end = endDate && new Date(endDate);
    const today = new Date();

    if (today >= start && today <= end) {
      return orderStatus === "ACTIVE" ? "Active" : "Cancelled";
    }

    return "Expired";
  };

  return (
    <>
      <Container
        className="payment-table"
      >
        <Typography sx={{ color: "white", padding: "12px", fontWeight: 600 }}>
          Payment History
        </Typography>
        <TableContainer
          sx={{
            border: "2px solid #283436",
            borderRadius: "15px",
            backgroundColor: "#0e1b1d",
            p: 1,
            width: "auto",
          }}
        >
          <Table
            sx={{ backgroundColor: "transparent" }}
            aria-label="simple table"
          >
            <TableHead>
              <TableRow>
                <TableCell
                  style={{ color: "#e29c2e", fontWeight: 800, fontSize: "17px" }}
                >
                  Program Name
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "#e29c2e", fontWeight: 800, fontSize: "17px" }}
                >
                  Price
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "#e29c2e", fontWeight: 800, fontSize: "17px" }}
                >
                  Purchased Date
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "#e29c2e", fontWeight: 800, fontSize: "17px" }}
                >
                  Expiry Date
                </TableCell>
                <TableCell
                  align="center"
                  style={{ color: "#e29c2e", fontWeight: 800, fontSize: "17px" }}
                >
                  Status
                </TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <CenterLoading open={loading} />
            ) : (
              <TableBody>
                {paymentHistoryList?.length > 0 ? (
                  paymentHistoryList.map((data) => (
                    <TableRow key={data.ID}>
                      <TableCell
                        component="th"
                        scope="row"
                        style={{ color: "whitesmoke" }}
                      >
                        {data?.js_programSubscription &&
                          data?.js_programSubscription?.name}
                      </TableCell>
                      <TableCell align="center" style={{ color: "whitesmoke" }}>
                        {`$ ${data?.amount && data?.amount} ${data?.currency && data?.currency?.toUpperCase()
                          } `}
                      </TableCell>
                      <TableCell align="center" style={{ color: "whitesmoke" }}>
                        {data?.createdAt &&
                          new Date(data?.createdAt).toLocaleDateString()}
                      </TableCell>
                      <TableCell align="center" style={{ color: "whitesmoke" }}>
                        {(data?.expirationTime &&
                          (data?.expirationTime === null
                            ? "Not Applicable"
                            : new Date(
                              data?.expirationTime
                            ).toLocaleDateString())) ||
                          "Not Applicable"}
                      </TableCell>
                      <TableCell align="center" style={{ color: "whitesmoke" }}>
                        {isPlanActive(data?.createdAt, data?.expirationTime, data?.orderStatus) === 'Active'
                          ? <Button onClick={() => {
                            setSubscriptionCancelModal(true)
                            setPaymentHistoryId(data?.ID)
                          }} sx={{ cursor: 'pointer', color: 'white', textTransform: 'capitalize', border: '1px solid rgb(52, 52, 54)' }}>
                            Cancel
                          </Button>
                          : isPlanActive(data?.createdAt, data?.expirationTime, data?.orderStatus)}
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={5}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                          textAlign: "center",
                          color: "white",
                          height: "100px",
                        }}
                      >
                        <Typography variant="h6">No Records Found</Typography>
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Container>


      <Modal
        open={subscriptionCancelModal}
        onClose={() => setSubscriptionCancelModal(false)}
      >
        <Box sx={{ ...cancelSubscriptionModalStyle }} width={"45%"} maxWidth={"600px"} minWidth={"350px"}>
          {/* Modal Header */}
          <box className="close-pop-wrap" >
            <Box className="Heading">
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#fff" }}
              >
                Cancel Subscription
              </Typography>
            </Box>
            <CloseIcon onClick={() => setSubscriptionCancelModal(false)} className="CloseImg" />
          </box>

          {/* Modal Body */}
          <div className="ContentWrapper">
            <Typography>
              You will continue to enjoy the benefits of being a subscribed member till the end of the current billing cycle. Thereafter, your account will have limited features until you renew again. Do you wish to proceed?
            </Typography>
          </div>

          {/* Modal Actions */}
          <Box className="modal-actions" display="flex" justifyContent="end" mt={2} marginRight={2}>
            <Button variant="outlined" color="secondary" sx={{ marginRight: 2 }} onClick={handleCancelSubscription}>
              Yes
            </Button>
            <Button variant="contained" color="primary" onClick={() => setSubscriptionCancelModal(false)} >
              No
            </Button>
          </Box>
        </Box>
      </Modal>

      <SnackBar
        open={openSnackBar.open}
        message={openSnackBar.message}
        handleCloseSnackBar={() => {
          setOpenSnackBar({
            open: false,
            message: '',
            severity: ''
          })
        }}
      />
    </>
  );
};

PaymentHistory.propTypes = {};
PaymentHistory.defaultProps = {};

const actionCreators = {
  getUserData
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(PaymentHistory);

const PaymentHistoryWrap = {
  // height:"calc(100vh - 7vh)",
}

const cancelSubscriptionModalStyle = {
  overflow: "hidden",
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90vh",
  backgroundColor: "white",
  boxShadow: 24,
  pb: 2,
  // padding: "20px",
  // overflowY: "auto", // Makes the content scrollable
  borderRadius: "8px",
  ".close-pop-wrap": {
    top: "0px",
    right: "0px",
    position: "sticky",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#112225",
    img: {
      width: "24px",
      height: "24px",
    },
  },
  ".Heading": {
    display: "flex",
    alignItems: "center",
    h2: {
      mb: 0,
      color: "#fff",
    },
    svg: {
      mr: 1,
      width: "20px",
      height: "20px",
      // def:{
      //   fill:"#fff"
      // },
      // path: {
      //   fill: "#d32f2f",
      // },
    },
  },
  ".CloseImg": {
    opacity: 1,
    borderRadius: "50%",
    p: "2px",
    border: "1px solid #fff",
    fontSize: "0.7rem",
    path: {
      fill: "#fff",
    },
  },
  ".ContentWrapper": {
    p: 2,
  },
};