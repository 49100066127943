import RouteContainer from "./routeContainer/RouteContainer";
import { BrowserRouter } from "react-router-dom";
import "./css/base.scss";
import ExperienceRatingModal from "./component/ExperenceRatingModal";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <RouteContainer />
      <ExperienceRatingModal />
    </BrowserRouter>
  );
}

export default App;
