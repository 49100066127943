import React from 'react';
import './footer.scss';
import { connect } from 'react-redux';
import { updateUserData } from '../../redux/action/user'
import { Box, Container, Divider, Typography } from '@mui/material';

const currentDate = new Date().getFullYear();
const Footer = (props) => {

    return (
        <>
            <Divider />
            <Container
                sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" }, // Column on small, Row on large
                    justifyContent: "space-between",
                    alignItems: "center",
                    textAlign: "center",
                    width: "100%",
                    padding: '18px 0px',
                    // gap: { xs: 2, md: 0 },
                    background: 'white'
                }}
            >
                <Box className='menu-list-section'>
                    <ul>
                        <li>
                            <a href='https://japa.health/japa-health-privacy-policy/' target='_blank' rel="noreferrer" alt='Privacy Policy'>Privacy Policy</a>
                        </li>
                        <li>
                            <a href='https://japa.health/japa-health-terms-of-use/' target={'_blank'} rel="noreferrer" alt='Terms of use'>Terms of use</a>
                        </li>
                        <li>
                            <a href='https://japa.health/japa-health-faqs/' target={'_blank'} rel="noreferrer" alt='FAQ'>FAQ's</a>
                        </li>
                    </ul>
                </Box>
                <Box className='copyright-section'>
                    <Typography>All rights reserved. © Copyright {currentDate}</Typography>
                </Box>
                <Box className='social-section'>
                    <ul>
                        <li>
                            <a href='https://www.linkedin.com/company/japa-health/' target={'_blank'} rel="noreferrer" alt='Linkedin'>
                                <svg
                                    // stroke="currentColor" 
                                    // fill="currentColor" 
                                    stroke-width="0" viewBox="0 0 256 256" height="25px" width="25px" xmlns="http://www.w3.org/2000/svg"><path d="M216,24H40A16,16,0,0,0,24,40V216a16,16,0,0,0,16,16H216a16,16,0,0,0,16-16V40A16,16,0,0,0,216,24Zm0,192H40V40H216V216ZM96,112v64a8,8,0,0,1-16,0V112a8,8,0,0,1,16,0Zm88,28v36a8,8,0,0,1-16,0V140a20,20,0,0,0-40,0v36a8,8,0,0,1-16,0V112a8,8,0,0,1,15.79-1.78A36,36,0,0,1,184,140ZM100,84A12,12,0,1,1,88,72,12,12,0,0,1,100,84Z"></path></svg>
                            </a>
                        </li>
                        <li>
                            <a href='http://instagram.com/japa.health' target={'_blank'} rel="noreferrer" alt='Instagram'>
                                <svg
                                    // stroke="currentColor"
                                    // fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 448 512"
                                    height="25px"
                                    width="25px"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                </svg>
                            </a>
                        </li>
                    </ul>
                </Box>
            </Container >
        </>
    );
};

Footer.propTypes = {};
Footer.defaultProps = {};

const actionCreators = {
    updateUserData
};

export default connect(
    ({ user }) => ({
        user,
    }),
    actionCreators
)(Footer);
