import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Container, Grid, Card, CardMedia, CardContent, Typography, Box } from '@mui/material';
import dummyImage from '../../assets/images/dummyImage.png'
import './course.scss'
import Header from '../../component/header';
import { getCoursesApi } from '../../redux/action/user';
import CenterLoading from '../../component/core/CenterLoading';


const Courses = (props) => {
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(false)

    const getCourses = async () => {
        setLoading(true)
        try {
            const data = await getCoursesApi();
            setCourses(data?.data);
        } catch (error) {
            // console.log('error', error)
        } finally {
            setLoading(false)
        }
    }
    useEffect(() => {
        getCourses();
    }, [])



    return (
        <>
            {loading ? (
                < CenterLoading open={loading} />
            ) : (
                <Container sx={{ padding: "5px", background: "#f9f9f9", minHeight: "calc(100vh - 140px)" }} className='courseList'>
                    <Box display="flex" justifyContent="center" alignItems="center" my={5}>
                        {courses?.length > 0 ? (
                            <Grid container spacing={3} justifyContent="center">
                                {courses.map((course, index) => (
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Card sx={{ maxWidth: 345, mx: "auto", minHeight: '380px' }}>
                                            <a href={course?.LmsLink || "#"} target="_blank" rel="noopener noreferrer">
                                                <CardMedia
                                                    component="img"
                                                    height="200"
                                                    image={course?.imageUrl || dummyImage}
                                                    alt={course?.courseName || "No image"}
                                                />
                                            </a>
                                            <CardContent sx={{ padding: '16px', textAlign: 'center' }}>
                                                <Typography
                                                    sx={{
                                                        ...textClampStyles(1),
                                                        fontWeight: 'bold',
                                                        fontSize: '1rem',
                                                        color: '#333',
                                                        textAlign: 'center'
                                                    }}
                                                >
                                                    <div dangerouslySetInnerHTML={{ __html: course?.courseName || " " }} />
                                                </Typography>

                                                <Typography
                                                    sx={{
                                                        ...textClampStyles(5),
                                                        fontSize: '0.9rem',
                                                        color: '#555',
                                                        marginTop: '8px',
                                                    }}
                                                >
                                                    <div dangerouslySetInnerHTML={{ __html: course?.description || " " }} />
                                                </Typography>
                                            </CardContent>

                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Typography
                                sx={{
                                    textAlign: 'center',
                                    fontSize: '1.1rem',
                                    fontWeight: 'bold',
                                    color: '#777',
                                    padding: '20px',
                                }}
                            >
                                🚀 No Courses Available! Please check back later.
                            </Typography>

                        )}
                    </Box>
                </Container>
            )}
        </>
    )
}



const actionCreators = {
};
export default connect(
    ({ user }) => ({
        user,
    }),
    actionCreators
)(Courses);


export const textClampStyles = (lines) => ({
    display: "-webkit-box",
    WebkitLineClamp: lines, // Dynamically set lines
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
    maxWidth: "100%",
    wordBreak: "break-word",
    textAlign: "justify",
    textTransform: 'capitalize'
});
