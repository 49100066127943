import React, { useEffect, useRef, useState } from "react";
import "./resultBody.scss";
import { connect } from "react-redux";
import StarsRating from "stars-rating";
import { useNavigate } from "react-router-dom";
import IosShareIcon from "@mui/icons-material/IosShare";
import CollapsBlock from "../../../atoms/collapsBlock";
import { Button, CircularProgress, IconButton, InputLabel, List, ListItem, Modal, Typography } from "@mui/material";
import { Box } from "@mui/system";
import closex from "../../../assets/images/x.png";
import heartTick from "../../../assets/images/heart-tick.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GradientSVG from "./gradientSVG";
import {
  getDeterminants,
  deleteUserResponse,
  updateSuggestion,
  updateReference,
  getCoursesApi,
} from "../../../redux/action/user";
import ButtonInput from "../../../atoms/button";
import MobileShareButton from "../../../atoms/button/mobileShareButton";
import CenterLoading from "../../core/CenterLoading";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  // TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
  // InstapaperShareButton,
  // InstapaperIcon,
} from "react-share";
import instagramImg from "../../../assets/images/instagram2.png";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "#fff",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const deleteStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "#fff",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const ResultBody = (props) => {
  const [open, setOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [starValue, setStarValue] = useState(0);
  const [imageToShare, setImageToShare] = useState();
  const navigate = useNavigate();
  const childRef = useRef();
  const [courses, setCourses] = useState([])
  const [courseLoading, setCourseLoading] = useState(true);
  const isSubscription =
    props.user &&
    props.user.subscription &&
    props.user.subscription.isSubscription;


  const getCourses = async () => {
    setCourseLoading(true);
    try {
      const data = await getCoursesApi();
      setCourses(data?.data);
      setCourseLoading(false)
    } catch (error) {
      // console.log('error', error)
      setCourseLoading(false)
    }
  }

  const setImageBase64 = async () => {
    const getBase64StringFromDataURL = (dataURL) =>
      dataURL.replace("data:", "").replace(/^.+,/, "");

    const image = await (await fetch("/demo-share-image.png")).blob();

    setImageToShare(
      new File([image], "score_share.png", {
        type: "image/png",
      })
    );

    const reader = new FileReader();

    reader.onloadend = () => {
      // Convert to Base64 string
      const base64 = getBase64StringFromDataURL(reader.result);
    };
    reader.readAsDataURL(image);
  };

  useEffect(() => {
    setImageBase64();
    getCourses()
    props.getDeterminants();
  }, []);

  const sortUserScores = () => {
    const sorted = (props.user.determinantList ?? [])
      .map((e) => {
        return {
          ...e,
          score: parseInt(e.score),
        };
      })
      .sort((a, b) => {
        if (b.score === a.score) return 0;
        if (b.score < a.score) return -1;
        return 1;
      });

    return sorted;
  };

  useEffect(() => {
    if (props.user.determinantList && props.user.determinantList.length > 0) {
      setStarValue(
        props.user.userFinalScore >= 90
          ? 5
          : props.user.userFinalScore >= 78 && props.user.userFinalScore <= 89
            ? 4
            : props.user.userFinalScore >= 68 && props.user.userFinalScore <= 77
              ? 3
              : props.user.userFinalScore >= 50 && props.user.userFinalScore <= 67
                ? 2
                : 1
      );
    }
  }, [props.user.determinantList]);

  const onSubmitQuestion = (tempModalData) => {
    setOpen(!open);
    setModalData(tempModalData);
    // console.log(tempModalData?.suggestionID);
    if (props.user._id && tempModalData?.suggestionID) {
      props.updateSuggestion(props.user._id, tempModalData.suggestionID);
    }
  };

  const openDeleteModal = () => {
    setDeleteModal(true);
  };

  const deleteUserData = () => {
    if (props.user._id) {
      props.deleteUserResponse(props.user._id, props.user.surveyId, navigate);
      setDeleteModal(false);
    }
  };

  const sortedData = sortUserScores();
  const highest = sortedData[0];
  const lowest = sortedData[sortedData.length - 1];

  const urlToShare = window.location.host.toLowerCase().includes("vitality")
    ? "https://vitality.japa.health"
    : "https://dev.japa.health/";
  // const urlToShare = "https://www.youtube.com/watch?v=Gmw_kcQT1Gg";

  const titleToShare = `I just found out I’m really great at ${highest?.Determinant}, and need some work on ${lowest?.Determinant}. Crack YOUR code at `;

  const courseContent = () => (
    <div class="courses_main">
      {isSubscription ? (
        <>
          <Typography
            variant="h5"
            style={{
              textTransform: "uppercase",
              fontWeight: "700",
              color: "#FFFFFF", // White for better contrast
              textShadow: "3px 3px 8px rgba(0, 0, 0, 0.8)", // Adds depth for visibility
            }}
          >
            Congrats! You have unlocked a lot more
          </Typography>

          <Box className="course_list">
            <List sx={{ padding: 0 }}>
              {courses?.length > 0 ? (
                courses?.slice(0, 3).map((course, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      padding: "4px 0px 0px 10px",
                      minHeight: "unset",
                      textTransform: "capitalize",
                    }}
                  >
                    <Typography
                      noWrap
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        display: "block",
                        color: "#E0E0E0",
                        fontWeight: "500",
                        textShadow: "2px 2px 6px rgba(0, 0, 0, 0.8)",
                      }}
                    >
                      {index + 1}. <span dangerouslySetInnerHTML={{ __html: course?.courseName || " " }} />
                    </Typography>
                  </ListItem>
                ))
              ) : courseLoading ? (
                <CircularProgress
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: "20px auto",
                  }}
                />
              ) : null}
            </List>
          </Box>

        </>
      ) : (
        <>
          <Typography
            variant="h6"
            textAlign="center"
            fontWeight="bold"
            sx={{ color: "#fff", mb: 1 }}
          >
            Subscribe to access all course content & features like goal setting, progress tracking.
          </Typography>
        </>
      )}
      <div class="view_course" style={{ marginTop: '15px' }}>
        <Box>
          <InputLabel
            style={{
              cursor: "pointer",
              backgroundColor: "#E29C2E",
              color: "#fff",
              width: "45%",
              borderRadius: "8px",
              padding: "10px",
              textAlign: "center",
              margin: 'auto'
            }}
            onClick={() => {
              navigate(isSubscription ? '/courses' : '/plans');
            }}
          >
            {isSubscription ? "View All Courses" : "Subscribe"}
          </InputLabel>
        </Box>
      </div>
    </div>
  )

  return (
    <div className="result-body" style={{ marginBottom: ' 10px' }}>
      <CenterLoading
        open={props.user.isLoading}
        showSuggestions
        suggestionList={[
          props.user.language?.loading_suggestions?.sugg_0,
          props.user.language?.loading_suggestions?.sugg_1,
          props.user.language?.loading_suggestions?.sugg_2,
          props.user.language?.loading_suggestions?.sugg_3,
          props.user.language?.loading_suggestions?.sugg_4,
        ]}
      />
      <div className="result-body__fixed bg-transparent">
        <div>
          <div
            className="flex-column"
            style={{ display: "flex", marginTop: "20px", gap: '20px' }}
          >
            <div className="chart-block_container">
              <div className="chart-block">
                <div className="chart-title">
                  <h2 className="white-color avenir-bold">Your Vitality Score</h2>
                </div>
                <GradientSVG />
                <div className="chart-detail">
                  <div className="chart-detail__chart">
                    <CircularProgressbar
                      value={props.user.userFinalScore}
                      strokeWidth={8}
                      text={`${props.user.userFinalScore}`}
                      styles={buildStyles({
                        rotation: 1 / 2,
                        pathColor: `url(#hello)`,
                        trailColor: "#264448",
                        textColor: "#fff",
                      })}
                      style={{
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </div>
                <div className="score-result">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <StarsRating
                      count={5}
                      value={starValue}
                      edit={false}
                      size={35}
                      color2={"#ffd700"}
                    />
                  </div>
                </div>
                <div className="shareButton">
                  <br />
                  <div className="web-share-buttons">
                    <FacebookShareButton
                      url={urlToShare}
                      quote={titleToShare + `${urlToShare}`}
                      title={titleToShare + `${urlToShare}`}
                      windowHeight={800}
                      windowWidth={1200}
                    >
                      <FacebookIcon round size={36} />
                    </FacebookShareButton>
                    <WhatsappShareButton
                      title={titleToShare}
                      url={urlToShare}
                      windowHeight={800}
                      windowWidth={1200}
                    >
                      <WhatsappIcon round size={36} />
                    </WhatsappShareButton>

                    <TwitterShareButton
                      url={urlToShare}
                      title={titleToShare}
                      windowHeight={800}
                      windowWidth={1200}
                    >
                      <XIcon round size={36} />
                    </TwitterShareButton>

                    {/* <InstapaperShareButton
                    url={urlToShare}
                    title={titleToShare}
                    windowHeight={800}
                    windowWidth={1200}
                  >
                    <InstapaperIcon round size={36} />
                  </InstapaperShareButton> */}

                    <LinkedinShareButton
                      url={urlToShare}
                      title={titleToShare + `${urlToShare}`}
                      summary={titleToShare + `${urlToShare}`}
                      // source={urlToShare}
                      windowHeight={800}
                      windowWidth={1200}
                    >
                      <LinkedinIcon round size={36} />
                    </LinkedinShareButton>
                    <IconButton
                      sx={{
                        background: "white",
                        height: "35px",
                        width: "35px",
                      }}
                    >
                      <a
                        href="http://instagram.com/japa.health"
                        target={"_blank"}
                        rel="noreferrer"
                        alt="Instagram"
                        style={{ textDecoration: "unset" }}
                      >
                        <img
                          src={instagramImg}
                          alt={instagramImg}
                          style={{ width: '100%', height: '100%', marginTop: '6px' }}
                        />
                      </a>
                    </IconButton>

                    <IconButton
                      sx={{
                        background: "white",
                        height: "35px",
                        width: "35px",
                      }}
                      disableRipple
                      onClick={async () => {
                        const sortedData = sortUserScores();
                        const highest = sortedData[0];
                        const lowest = sortedData[sortedData.length - 1];

                        const image = await (
                          await fetch("/demo-share-image.png")
                        ).blob();

                        const handleShare = {
                          //title: "Share Your Score!",
                          text: titleToShare + `${urlToShare}`,
                          files: [
                            new File([image], "score_share.png", {
                              type: "image/png",
                            }),
                          ],
                        };

                        try {
                          await navigator.share(handleShare);
                        } catch (err) { }
                      }}
                    >
                      <IosShareIcon
                        sx={{
                          color: "black",
                          fontSize: "18px",
                        }}
                      />
                    </IconButton>
                  </div>

                  <div className="mobile-share-buttons">
                    <MobileShareButton
                      class={`progress-footer__button`}
                      text={`Share`}
                      onSubmit={async () => {
                        const sortedData = sortUserScores();
                        const highest = sortedData[0];
                        const lowest = sortedData[sortedData.length - 1];
                        /* const getBase64StringFromDataURL = (dataURL) =>
                          dataURL.replace("data:", "").replace(/^.+,/, ""); */

                        const image = await (
                          await fetch("/demo-share-image.png")
                        ).blob();
                        /* const reader = new FileReader();
   
                        reader.onloadend = () => {
                          // Convert to Base64 string
                          const base64 = getBase64StringFromDataURL(
                            reader.result
                          );
                        };
                        reader.readAsDataURL(image); */

                        const handleShare = {
                          //title: "Share Your Score!",
                          text: titleToShare + urlToShare,
                          files: [
                            new File([image], "score_share.png", {
                              type: "image/png",
                            }),
                          ],
                        };

                        try {
                          await navigator.share(handleShare);
                        } catch (err) { }
                      }}
                    />
                  </div>
                </div>
                {/* <div className="discount">
                {(window.location.host.toLowerCase().includes("vesync") && (
                  <a
                    target="_blank"
                    href="https://us.vesync.com/"
                    rel="noreferrer"
                  >
                    {props.user.language.partner_coupons.vesync_line1}
                    <br />
                    {props.user.language.partner_coupons.vesync_line2}
                  </a>
                )) ||
                  (window.location.host.toLowerCase().includes("scp") && (
                    <a
                      target="_blank"
                      href="https://www.japa.health/"
                      rel="noreferrer"
                    >
                      {props.user.language.partner_coupons.scp}
                    </a>
                  )) ||
                  (window.location.host
                    .toLowerCase()
                    .includes("stream2sea") && (
                    <a
                      target="_blank"
                      href="https://www.japa.health/"
                      rel="noreferrer"
                    >
                      {props.user.language.partner_coupons.stream2sea}
                    </a>
                  )) ||
                  (window.location.host.toLowerCase().includes("cor") && (
                    <a
                      target="_blank"
                      href="https://cor.japa.health/"
                      rel="noreferrer"
                    >
                      {props.user.language.partner_coupons.cor}
                    </a>
                  )) || (
                    //default case(for vitality.japa.health or any other)
                    <a
                      target="_blank"
                      href="https://us.vesync.com/"
                      rel="noreferrer"
                    >
                      {props.user.language.partner_coupons.vitality_line1}
                      <br />
                      {props.user.language.partner_coupons.vitality_line2}
                    </a>
                  )}
              </div> */}
                <Box
                  sx={{
                    marginTop: "10px",
                    marginBottom: "5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <InputLabel
                    style={{
                      cursor: "pointer",
                      backgroundColor: "#E29C2E",
                      color: "#fff",
                      width: "45%",
                      borderRadius: "8px",
                      padding: "10px",
                      textAlign: "center",
                    }}
                    onClick={() => {
                      localStorage.removeItem("progressDone");
                      localStorage.removeItem("detailDone");
                      localStorage.setItem("review", "true");
                      navigate("/progress");
                    }}
                  >
                    Review My Answers
                  </InputLabel>
                </Box>
              </div>

              <Box className="chart-block__course">
                {courseContent()}
              </Box>
            </div>


            <Box className="result-block__container">
              <Box className="result-block">
                {props.user.determinantList &&
                  props.user.determinantList.length > 0 && (
                    <CollapsBlock
                      deleteVisible={props.user.emailaddress
                        .toLowerCase()
                        .endsWith("@japa.health")}
                      modelOpen={onSubmitQuestion}
                      data={props.user.determinantList}
                      deleteUserData={openDeleteModal}
                      ref={childRef}
                    />
                  )}
              </Box>
              <Box mt={5} className="result-block__course">
                {courseContent()}
              </Box>
            </Box>
          </div>
        </div>
      </div>
      <Modal open={open}>
        <Box sx={style} className="inner-success-modal w-95">
          <div className="ab-close-pop-wrap">
            <img
              src={closex}
              alt={closex}
              onClick={() => onSubmitQuestion({})}
            ></img>
          </div>
          <div className="out-card-box-wrap">
            <div className="left-image-wrap">
              <img src={heartTick} alt={heartTick}></img>
            </div>
            <div className="right-desc-wrap">
              <b className="avenir-bold">{modalData.Suggestion}</b>
              <p>{modalData.DeterminantName}</p>
            </div>
          </div>
          <div className="inner-body-pop-up-wrap">
            <p>{modalData.Blurb}</p>
            <p style={{ justifyContent: "space-around", display: "flex" }}>
              {/* <a href={modalData.lmsUrl} target="_blank" rel="lms">
                    {modalData.lmsUrl}
                    https://japa.health/wordpress-dev/courses/learndash-101/
                  </a> */}
              {modalData.lmsUrl && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => window.open(modalData.lmsUrl, "_blank")}
                >
                  Proceed to Goal
                </Button>
              )}
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  props.updateReference(props.user._id, modalData.suggestionID);

                  let url = modalData.ReferenceLink;

                  // Ensure the URL starts with http:// or https://
                  if (url && !url.startsWith("http://") && !url.startsWith("https://")) {
                    url = "https://" + url;
                  }

                  window.open(url, "_blank");
                }}
              >
                The Science
              </Button>

              {isSubscription && modalData &&
                modalData.goalstatus &&
                modalData.goalstatus.icon === "in-progress-icon" && (
                  <Button
                    variant="contained"
                    onClick={() => {
                      childRef &&
                        childRef.current.handleGoalsCancel(modalData, "remove");
                      setOpen(false);
                    }}
                    sx={{
                      backgroundColor: "rgb(243 168 6)",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "rgb(243 168 6)",
                      },
                    }}
                  >
                    Cancel Goal
                  </Button>
                )}

              {/* <a
                href={modalData.ReferenceLink}
                target="_blank"
                rel="noreferrer"
                onClick={() => {}}
              >
                {modalData.ReferenceLink}
              </a> */}
            </p>
          </div>
        </Box>
      </Modal>

      <Modal
        open={deleteModal}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={deleteStyle} className="delete-inner-success-modal">
          <div className="delete-out-card-box-wrap">
            <div className="left-image-wrap text-center w-100">
              <h3>Clear And Restart?</h3>
            </div>
          </div>
          <div className="delete-inner-body-pop-up-wrap">
            <div className="button-container" style={{ display: "flex" }}>
              <ButtonInput
                class="delete-button ok-button"
                text={"Clear"}
                onSubmit={() => deleteUserData()}
              />
              <ButtonInput
                class="ok-button"
                text={"Cancel"}
                onSubmit={() => setDeleteModal(false)}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

ResultBody.propTypes = {};
ResultBody.defaultProps = {};

const actionCreators = {
  getDeterminants,
  deleteUserResponse,
  updateSuggestion,
  updateReference,

};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(ResultBody);
