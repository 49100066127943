import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';


const Logout = () => {
    const navigate = useNavigate()
    useEffect(() => {
        localStorage.clear();
        sessionStorage.clear()
        navigate('/signin');
        window.location.reload();
    }, []);

    return null;
}

export default Logout