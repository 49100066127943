import React, { useEffect } from "react";
import "./progress.scss";
import { connect } from "react-redux";
import { updateUserData, getUserData } from "../../redux/action/user";
import ProgressBody from "../../component/progress/progressBody";
import { useNavigate } from "react-router";
import ProgressHeader from '../../component/progress/progressHeader'

const Progress = (props) => {
  const navigate = useNavigate();

  useEffect(() => {
    props.getUserData(localStorage.getItem("userKey"), navigate);
  }, []);

  return (
    <>
      <ProgressHeader />
      <ProgressBody />
    </>
  );
};

Progress.propTypes = {};
Progress.defaultProps = {};

const actionCreators = {
  updateUserData,
  getUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(Progress);
