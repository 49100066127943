import React, { useEffect, useState } from "react";
import "./login.scss";
import { connect } from "react-redux";
import {
  updateUserData,
  loginUser,
} from "../../redux/action/user";
// import TextBox from "../../atoms/textbox";
import TextBox2 from "../../atoms/textBox2";
import ButtonInput from "../../atoms/button";
import LabelInput from "../../atoms/label";
import { Checkbox, FormControlLabel, FormHelperText } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
// import CountryDropDown from "../../atoms/countryDropDown";
import SnackBar from "../../atoms/snackBar";
import Cookies from "js-cookie";

import "react-phone-number-input/style.css";
import PhoneInputWithCountrySelect from "react-phone-number-input";
// import { getCountryCallingCode } from "react-phone-number-input";
import { parsePhoneNumber } from "react-phone-number-input";

const LoginSection = (props) => {
  const [activeButton, setActiveButton] = useState(false);
  const [captchaValue, setCaptchaValue] = useState("");
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [rememberToggle, setRememberToggle] = useState(false);
  const [mobileError, setMobileError] = useState("");

  const enableButtonChange = () => {
    if (
      (props.user.loginForm.emailaddress && captchaValue) ||
      (props.user.loginForm.mobileNumber && captchaValue)
    ) {
      const parsedPhone = parsePhoneNumber(
        props.user?.loginForm?.mobileNumber ?? ""
      );
      const phoneNumber = removeCountryCode({
        code: "+" + parsedPhone?.countryCallingCode,
        phone: props.user.loginForm.mobileNumber,
      });
      if (parsedPhone && phoneNumber.length > 0) {
        if (parsedPhone && phoneNumber.length > 8) {
          setActiveButton(true);
        } else {
          setActiveButton(false);
        }
      } else {
        setActiveButton(true);
      }
    } else {
      setActiveButton(false);
    }
  };

  useEffect(() => {
    if (props.user?.loginError) {
      setErrorMessage(props.user?.loginError);
    }
  }, [props.user?.loginError]);

  useEffect(() => {
    if (errorMessage) {
      setOpenSnackBar(true);
    }
  }, [errorMessage]);

  const handleCloseSnackBar = () => {
    setOpenSnackBar(false);
    setErrorMessage("");
  };

  useEffect(() => {
    enableButtonChange();
  }, [props.user, captchaValue]);

  useEffect(() => {
    props.updateUserData({
      ...props.user,
      emailaddress: Cookies.get("emailaddress")
        ? Cookies.get("emailaddress")
        : "",
      countryCode:
        Cookies.get("mobileNumber") && Cookies.get("countryCode")
          ? Cookies.get("countryCode")
          : "+1",
      mobileNumber: Cookies.get("mobileNumber")
        ? Cookies.get("mobileNumber")
        : "",
    });
  }, []);

  const redirectToPage = (page) => {
    if (props.changePage) props.changePage(page);
  };

  const emailChange = (emailid) => {
    const value = emailid.toLowerCase()
    props.updateUserData({
      ...props.user,
      loginForm: {
        ...props.user.loginForm,
        emailaddress: value,
      },
    });
  };

  const gotoOTP = () => {
    const phone = parsePhoneNumber(props.user.loginForm.mobileNumber);
    const phoneNumber = removeCountryCode({
      code: "+" + phone?.countryCallingCode,
      phone: props?.user?.loginForm?.mobileNumber,
    });
    if (phoneNumber.length > 0 && phoneNumber.length !== 10) {
      setMobileError("Phone number must be of length 10");
      return;
    }

    setMobileError("");

    if (rememberToggle) {
      Cookies.set("emailaddress", props?.user?.loginForm?.emailaddress, 1);
      Cookies.set("countryCode", props?.user?.loginForm?.countryCode, 1);
      Cookies.set("mobileNumber", props?.user?.loginForm?.mobileNumber, 1);
    }
    // console.log(phoneNumber);
    props.loginUser({
      emailaddress: props.user.loginForm.emailaddress,
      countryCode: "+" + (phone?.countryCallingCode ?? ""),
      mobileNo: phoneNumber,
      // mobileNo: props.user.loginForm.mobileNumber
      // mobileNo: props.user.loginForm.mobileNumber.replace(
      //     '+'+phone.countryCallingCode,
      //     ""
      // ),
    });

    redirectToPage("otp");
  };

  const removeCountryCode = (data) => {
    // console.log(data);
    var newStr = data.phone.replace(data.code, "");
    return newStr;
  };

  const gotoSignup = () => {
    props.updateUserData({
      ...props.user,
      isNewUser: true,
    });
    redirectToPage("signup");
  };

  const onChange = (value) => {
    setCaptchaValue(value);
  };

  const mobileNoChange = (value) => {
    if (mobileError) {
      setMobileError(undefined);
    }
    if (value.length >= 0 && value.length <= 15) {
      props.updateUserData({
        ...props.user,
        loginForm: {
          ...props.user.loginForm,
          mobileNumber: value,
        },
      });
    }
  };

  const countryCodeChange = (value) => {
    props.updateUserData({
      ...props.user,
      loginForm: {
        ...props.user.loginForm,
        countryCode: value,
      },
    });
  };

  return (
    <div className="login_form">
      <div className="container-fluid">
        <div className="row">
          <h2 className="white-color avenir-bold">
            {props.user.language?.login?.title}
          </h2>
          {/* <p className='subTitle '>
                        Login as an Student
                    </p> */}
        </div>
        <TextBox2
          placeholder={props.user.language?.login?.emailPlaceHolder}
          emailid={props.user.loginForm.emailaddress}
          valueChange={emailChange}
        />

        <h4 className="or-condition">
          <LabelInput text={props.user.language?.login?.orCondition} />
        </h4>
        {/* <CountryDropDown
                    dropDownDefaultValue={props.user.loginForm.countryCode}
                    placeholder={props.user.language?.login?.mobilePlaceHolder}
                    type="number"
                    value={props.user.loginForm.mobileNumber}
                    valueChange={mobileNoChange}
                    countryCodeChange={countryCodeChange} /> */}

        <div>
          <div className="PhoneInput">
            <PhoneInputWithCountrySelect
              defaultCountry={"US"}
              value={props.user.loginForm.mobileNumber}
              /* onCountryChange={(country) => {
                                if (!country) return
                                countryCodeChange('+' + getCountryCallingCode(country))
                            }} */
              onChange={(val) => {
                if (val) {
                  mobileNoChange(val);
                }
              }}
              className={"cus-phone-input"}
              placeholder="1234567890"
            />
          </div>
          {mobileError && <span className="input-error">{mobileError}</span>}
        </div>

        <div className="row flex remember-container">
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  className="yellow-font"
                  defaultChecked={Cookies.get("emailaddress") ? true : false}
                  onClick={() => setRememberToggle(!rememberToggle)}
                />
              }
              label={props.user.language?.login?.rememberMe}
              className="cus-label font-transform"
            />
          </div>
        </div>
        <ReCAPTCHA
          sitekey="6Lf7GkQiAAAAAF-nHbYNl_mR1V0rYOA5KKOCRHvW"
          onChange={onChange}
        />
        <div className="row">
          <ButtonInput
            class={`getOtp-btn ${activeButton ? "active-btn" : "deactive-btn"
              } margin-bottom-10`}
            text={props.user.language?.general?.getOtpButton}
            disabled={!activeButton}
            onSubmit={gotoOTP}
          />
        </div>
        <div
          className="row font-size-14 benton-sans-font"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
            flexWrap: "nowrap",
            whiteSpace: "nowrap",
          }}
        >
          <div
            style={{ cursor: "pointer", width: "fit-content" }}
            onClick={() => gotoSignup()}
          >
            <LabelInput
              text={props.user.language?.login?.signUpText}
              className="yellow-font sign-up-welcome font-transform font-size-14 benton-sans-font"
              style={{ marginBottom: '-10px' }}
            />
          </div>
          <p className="white bottom-section" style={{ marginBottom: 0 }}>
            {props.user.language?.support?.text}
            <span
              className="yellow-font sign-up-welcome"
              style={{ marginLeft: "5px" }}
            >
              <a
                className="yellow-font sign-up-welcome"
                href={`mailto:${props.user.language?.support?.email}`}
                style={{ textDecoration: "none", color: "inherit" }}
              >
                {props.user.language?.support?.email}
              </a>
            </span>
          </p>
        </div>

      </div>
      <SnackBar
        open={openSnackBar}
        message={errorMessage}
        handleCloseSnackBar={handleCloseSnackBar}
      />
    </div>
  );
};

LoginSection.propTypes = {};
LoginSection.defaultProps = {};

const actionCreators = {
  updateUserData,
  loginUser,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(LoginSection);
