export const headerLinks = [
    {
        id: 'home',
        pathname: '/result' || '/progress',
        label: 'Last Session'
    },
    {
        id: 'courses',
        pathname: '/courses',
        label: 'Courses'
    },
    {
        id: 'plans',
        pathname: '/plans',
        label: 'Programs & Subscription'
    },
    {
        id: 'history',
        pathname: '/history',
        label: 'Assessments'
    },
    {
        id: 'payHistory',
        pathname: '/payHistory',
        label: 'Payment History'
    },
]