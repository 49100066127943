import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Routes, Route, Navigate, useRoutes } from "react-router-dom";
import Login from "../pages/login";
import Signin from "../pages/login/signin";
import ProfileDetail from "../pages/profileDetail";
import Progress from "../pages/progress";
import Result from "../pages/result";
import ScrollToTop from "./ScrollToTop";
import History from "../pages/history";
import Payment from "../pages/plansNSubscription/Payment";
import PlanSubscription from "../pages/plansNSubscription/PlanSubscription";
import PaymentSuccess from "../pages/plansNSubscription/PaymentSuccess";
import PaymentFailed from "../pages/plansNSubscription/PaymentFailed";
import PaymentHistory from "../pages/plansNSubscription/PaymentHistory";
import NotFound from "../pages/NotFound";
import Courses from "../pages/courses";
import Logout from "../pages/logout";

const RouteContainer = (props) => {
  const isSubscription = props?.user?.subscription?.isSubscription;
  const token = localStorage.getItem('userKey');

  const routes = [
    { path: "/", element: <Signin /> },
    { path: "/signin", element: <Signin /> },
    { path: "/signup", element: <Login /> },
    {
      path: "/",
      element: token ? <ScrollToTop /> : <Navigate to={'/signin'} />,
      children: [
        { path: "profiledetail", element: <ProfileDetail /> },
        { path: "progress", element: <Progress key={props?.user?.surveyId} /> },
        { path: "result", element: <Result key={props?.user?.surveyId} /> },
        { path: "history", element: <History /> },
        { path: "plans", element: <PlanSubscription /> },
        { path: "payment", element: <Payment /> },
        { path: "payHistory", element: <PaymentHistory /> },
        { path: "paymentSuccess", element: <PaymentSuccess /> },
        { path: "paymentFailed", element: <PaymentFailed /> },
        { path: "goals", element: <Result key={props?.user?.surveyId} /> },
        { path: "courses", element: isSubscription ? <Courses /> : <Navigate to="/" /> },
        { path: "*", element: <NotFound /> },
      ],
    },
    { path: 'logout', element: <Logout /> },
    { path: "*", element: <NotFound /> },
  ];

  return useRoutes(routes);
};

RouteContainer.propTypes = {};
RouteContainer.defaultProps = {};

const actionCreators = {};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(RouteContainer);
