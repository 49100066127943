import { Box } from "@mui/material";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../component/header";
import Footer from "../component/footer";

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Box>
      <Header />
      <Outlet />
      {pathname !== "/progress" && <Footer />}
    </Box>
  );
}
