import React from "react";
import { Container, Typography, Button, Paper, Box } from "@mui/material";
import { useNavigate } from "react-router";

const PaymentFailed = (props) => {
  const navigate = useNavigate();


  return (
    <>
      <Container maxWidth="sm" style={{ marginTop: "20px", height: '72vh' }}>
        <Paper elevation={3} style={{ padding: "20px", textAlign: "center" }}>
          <Typography variant="h4" color="error.main">
            Payment Failed
          </Typography>
          <Typography variant="body1" style={{ margin: "20px 0" }}>
            We're sorry, but your payment could not be processed. Please try
            again.
          </Typography>

          {/* {orderId && (
            <Typography variant="body2">
              <strong>Order ID:</strong> {orderId}
            </Typography>
          )} */}

          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/plans")}
            >
              Retry Payment
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() =>
                (window.location.href = "https://japa.health/contact/")
              }
            >
              Contact Support
            </Button>
          </Box>
        </Paper>
      </Container>
    </>
  );
};

export default PaymentFailed;
