import React from "react";
import "./result.scss";
import { connect } from "react-redux";
import { updateUserData } from "../../redux/action/user";
import ResultBody from "../../component/result/resultBody";
import { useEffect } from "react";
import { Container } from "@mui/material";

const Result = (props) => {
  useEffect(() => {
    localStorage.removeItem("review");
  }, []);

  return (
    <>
      <Container>
        <ResultBody />
      </Container>
    </>
  );
};

Result.propTypes = {};
Result.defaultProps = {};

const actionCreators = {
  updateUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(Result);
