import React, { Fragment, useEffect, useState } from "react";
import "./plans.scss";
import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import closex from "../../assets/images/x.png";
import { connect } from "react-redux";
import SnackBar from "../../atoms/snackBar";
import { cancelSubscriptionApi, getProgramListApi, getUserData, paymentCheckoutURLApi } from "../../redux/action/user";
import "react-quill/dist/quill.snow.css";
import CenterLoading from "../../component/core/CenterLoading";
import CloseIcon from "@mui/icons-material/Close";

const PlanSubscription = (props) => {
  const [desc, setDesc] = useState("");
  const [descModal, setDescModal] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const [selectedPgrm, setSelectedPgrm] = useState({});
  const [loading, setLoading] = useState(false)
  const [programList, setProgramList] = useState([]);
  const [subscriptionCancelModal, setSubscriptionCancelModal] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState({
    open: false,
    message: '',
    severity: ''
  })

  const getProgramList = async () => {
    setLoading(true)
    try {
      const res = await getProgramListApi();
      setProgramList(res?.data?.data?.programList
      )
    } catch (error) {
      console.log(error)
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getProgramList()
  }, []);

  const subscriptionList = programList?.filter((item) => item.isSubscription === true);

  const programsList = programList?.filter((item) => !item.isSubscription);

  // Handle the expansion of the accordion
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  // Style for the modal container
  const modalStyle = {
    overflow: "hidden",
    position: "relative",
    // height: "80vh",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80%",
    maxHeight: "90vh",
    backgroundColor: "white",
    boxShadow: 24,
    padding: "20px",
    // overflowY: "auto", // Makes the content scrollable
    borderRadius: "8px",
    ".close-pop-wrap": {
      top: "0px",
      right: "0px",
      position: "sticky",
      padding: "10px",
      textAlign: "right",
      img: {
        width: "24px",
        height: "24px",
      },
    },
  };

  const isPlanPurchased = (plan) => {
    const isProgramPurchased =
      props.user.programPurchased &&
      props.user.programPurchased?.some(
        (purchase) => purchase.programSubscriptionId === plan.ID
      );

    if (plan.isSubscription) {
      return (
        (props.user.subscription &&
          props.user.subscription.isSubscription &&
          props.user.subscription.programSubscriptionId == plan.ID &&
          plan.isSubscription) ||
        isProgramPurchased
      );
    }
    return isProgramPurchased;
  };

  const handlePayment = async (ele) => {
    // const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY);
    const payload = {
      ID: ele.ID,
    }
    try {
      const res = await paymentCheckoutURLApi(payload);
      window.location.href = res.data.data.url;
    } catch (error) {
      setOpenSnackBar({
        open: true,
        message: error?.message,
        severity: 'error'
      })
      getProgramList()
    }
  };

  const handleSeeMore = (description) => {
    setDesc(description);
    setDescModal(true);
  };

  const formatDuration = (duration, interval) => {
    const formattedInterval = duration > 1 ? `${interval}s` : interval;
    return `${duration} ${formattedInterval}`;
  };

  const handleActivePlansAndSubscription = (plans) => {
    return isPlanPurchased(plans) || plans?.status === "1"
  }

  const handleCancelSubscription = async () => {
    setLoading(true)
    try {
      const res = await cancelSubscriptionApi('');
      setOpenSnackBar({
        open: true,
        message: res?.data?.message,
        severity: 'success'
      })

      await getProgramList();
      await props.getUserData(localStorage.getItem("userKey"));

    } catch (error) {
      setOpenSnackBar({
        open: true,
        message: error?.message,
        severity: 'error'
      })
    } finally {
      setSubscriptionCancelModal(false);
      setLoading(false)
    }
  };

  const cards = (ele) => (
    <Grid item xs={12} sm={6} md={4} key={ele.ID}>
      <div className="plan-block">
        <div className="plan-title">
          <Typography variant="h6" className="white-color avenir-bold">
            {ele.isSubscription ? "Become A Member" : "PROGRAM"}
          </Typography>
        </div>
        <div className="plan-detail" style={{ textAlign: "center" }}>
          <Typography variant="h6" className="plan-head">
            {ele.name}
          </Typography>
          <div className="ImgWrap">
            <CardMedia
              component="img"
              alt={ele.name}
              image={ele.imageUrl}
              title={ele.name}
            />
          </div>

          <Typography className="plan-desc" px={2}>
            {ele.description && ele.description.length > 120 ? (
              <>
                {ele.description.slice(0, 120)}...
                <span
                  className="see-more"
                  onClick={() => {
                    handleSeeMore(ele.description);
                    setSelectedPgrm(ele);
                  }}
                  style={{ cursor: "pointer", color: "blue" }}
                >
                  See more
                </span>
              </>
            ) : (
              ele.description
            )}
          </Typography>
          <Typography
            className="plan-desc"
            style={{ textDecoration: "underline", cursor: 'pointer' }}
            onClick={() => {
              handleSeeMore(ele.description);
              setSelectedPgrm(ele);
            }}
          >
            Details
          </Typography>
        </div>
        <div className="BuyNowOption">
          <div className="TextWrap">
            <Typography>
              Duration:{" "}
              {/* {ele.duration && formatDuration(ele.duration, ele.interval)} */}
              {ele?.interval}
            </Typography>
            <Typography>
              Price:{" "}
              {`$${ele.amount} ${ele.currency && ele.currency.toUpperCase()}`}
            </Typography>
          </div>

          <Box sx={{ display: 'flex', gap: '15px' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                if (isPlanPurchased(ele) && !ele?.isSubscription && ele?.url) {
                  window.open(ele.url, "_blank");
                } else if (isPlanPurchased(ele) && ele.isSubscription && !props?.user?.subscription?.isCancelled) {
                  setSubscriptionCancelModal(true)
                } else {
                  handlePayment(ele);
                }
              }}
              className="buy-now-button"
              // disabled={ele.isSubscription && isPlanPurchased(ele) && !props?.user?.subscription?.isCancelled}
              sx={{
                backgroundColor:
                  ele.isSubscription && isPlanPurchased(ele) && !props?.user?.subscription?.isCancelled
                    ? "grey !important"
                    : "#e29c2e !important",
                // pointerEvents:
                //   ele.isSubscription && isPlanPurchased(ele) && !props?.user?.subscription?.isCancelled
                //     ? "none"
                //     : "auto",
                cursor: "pointer",
              }}
            >
              {ele.isSubscription
                ? isPlanPurchased(ele)
                  ? props?.user?.subscription?.isCancelled
                    ? "Buy Now"
                    : "Cancel"
                  : "Buy Now"
                : isPlanPurchased(ele)
                  ? "Proceed to Program"
                  : "Buy Now"}

            </Button>
          </Box>
        </div>
      </div>
    </Grid>
  );
  return (
    <>
      {loading ? (
        <CenterLoading open={loading} />
      ) : (
        <Container className="plans">
          {subscriptionList?.filter(handleActivePlansAndSubscription)?.length > 0 && (
            <Fragment>
              <Typography
                sx={{
                  padding: "10px",
                  color: "#fff",
                  backgroundColor: "#121c1f",
                  borderRadius: "10px",
                  width: "97%",
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontWeight: 'bold',
                }}
              >
                Subscriptions
              </Typography>
              <Grid container spacing={2} marginTop={1} marginBottom={4} justifyContent="center">
                {subscriptionList?.filter(handleActivePlansAndSubscription)?.map((ele, index) => cards(ele, index))}
              </Grid>
            </Fragment>
          )}
          {programsList?.filter(handleActivePlansAndSubscription)?.length > 0 && (
            <Fragment>
              <Typography
                sx={{
                  padding: "10px",
                  color: "#fff",
                  backgroundColor: "#121c1f",
                  borderRadius: "10px",
                  width: "97%",
                  textAlign: 'center',
                  textTransform: 'uppercase',
                  fontWeight: 'bold'
                }}
              >
                Programs
              </Typography>
              <Grid container spacing={2} marginTop={1} justifyContent="center">
                {programsList?.filter(handleActivePlansAndSubscription)?.map((ele, index) => cards(ele, index))}
              </Grid>
            </Fragment>
          )}
          {(subscriptionList?.filter(handleActivePlansAndSubscription)?.length === 0 &&
            programsList?.filter(handleActivePlansAndSubscription)?.length === 0) && (
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                height="66vh"
                sx={{ textAlign: "center", flex: "auto" }} // MUI sx prop for styling
              >
                <Typography variant="h6">Stay tuned to check the available programs and subscriptions.</Typography>
              </Box>
            )}
        </Container>
      )}
      <Modal open={descModal} onClose={() => setDescModal(false)}>
        <Box sx={modalStyle}>
          <div className="close-pop-wrap">
            <img
              src={closex}
              alt={closex}
              onClick={() => setDescModal(false)}
            ></img>
          </div>
          <div>
            {selectedPgrm && (
              <HtmlContent rawHtmlContent={selectedPgrm.summary} />
            )}
          </div>
        </Box>
      </Modal>
      <Modal
        open={subscriptionCancelModal}
        onClose={() => setSubscriptionCancelModal(false)}
      >
        <Box sx={{ ...cancelSubscriptionModalStyle }} width={"45%"} maxWidth={"600px"} minWidth={"350px"}>
          {/* Modal Header */}
          <box className="close-pop-wrap" >
            <Box className="Heading">
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#fff" }}
              >
                Cancel Subscription
              </Typography>
            </Box>
            <CloseIcon onClick={() => setSubscriptionCancelModal(false)} className="CloseImg" />
          </box>

          {/* Modal Body */}
          <div className="ContentWrapper">
            <Typography>
              You will continue to enjoy the benefits of being a subscribed member till the end of the current billing cycle. Thereafter, your account will have limited features until you renew again. Do you wish to proceed?
            </Typography>
          </div>

          {/* Modal Actions */}
          <Box className="modal-actions" display="flex" justifyContent="end" mt={2} marginRight={2}>
            <Button variant="outlined" color="secondary" sx={{ marginRight: 2 }} onClick={handleCancelSubscription}>
              Yes
            </Button>
            <Button variant="contained" color="primary" onClick={() => setSubscriptionCancelModal(false)} >
              No
            </Button>
          </Box>
        </Box>
      </Modal>


      <SnackBar
        open={openSnackBar.open}
        message={openSnackBar.message}
        severity={openSnackBar.severity}
        handleCloseSnackBar={() => {
          setOpenSnackBar({
            open: false,
            message: '',
            severity: ''
          })

        }}
      />
    </>
  );
};

PlanSubscription.propTypes = {};
PlanSubscription.defaultProps = {};

const actionCreators = {
  // getProgramList,
  getUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(PlanSubscription);

const HtmlContent = ({ rawHtmlContent }) => {
  // Decode the raw HTML content
  const decodedHtml = decodeHtmlEntities(rawHtmlContent);

  return <div dangerouslySetInnerHTML={{ __html: decodedHtml }} />;
};

export function decodeHtmlEntities(text) {
  const doc = new DOMParser().parseFromString(text, "text/html");
  return doc.documentElement.textContent || doc.documentElement.innerText;
}


const cancelSubscriptionModalStyle = {
  overflow: "hidden",
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90vh",
  backgroundColor: "white",
  boxShadow: 24,
  pb: 2,
  borderRadius: "8px",
  ".close-pop-wrap": {
    top: "0px",
    right: "0px",
    position: "sticky",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#112225",
    img: {
      width: "24px",
      height: "24px",
    },
  },
  ".Heading": {
    display: "flex",
    alignItems: "center",
    h2: {
      mb: 0,
      color: "#fff",
    },
    svg: {
      mr: 1,
      width: "20px",
      height: "20px",
    },
  },
  ".CloseImg": {
    opacity: 1,
    borderRadius: "50%",
    p: "2px",
    border: "1px solid #fff",
    fontSize: "0.7rem",
    path: {
      fill: "#fff",
    },
  },
  ".ContentWrapper": {
    p: 2,
  },
};