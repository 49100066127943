import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Toolbar,
  Typography,
  Container,
  Menu,
  MenuItem,
  useMediaQuery,
  Divider,
  Modal,
  Button,
} from "@mui/material";
import Logo from "../../assets/images/japaLogo.png";
import { headerLinks } from "./data";
import { getSurveyList, getUserData, sendInviteForLabsApi, setUserData } from "../../redux/action/user";
import "./header.scss";
import { ExpandMore } from "@mui/icons-material";
import SnackBar from "../../atoms/snackBar";
import CloseIcon from "@mui/icons-material/Close";
import CenterLoading from "../core/CenterLoading";


const Header = (props) => {
  const navigate = useNavigate();
  const token = localStorage.getItem("userKey");
  const [anchorEl, setAnchorEl] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [loading, setLoading] = useState(false)
  const [openSnackBar, setOpenSnackBar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const isSmallScreen = useMediaQuery("(max-width: 1200px)");

  const location = useLocation();
  const dispatch = useDispatch();
  const isSubscription =
    props.user && props.user.subscription && props.user.subscription.isSubscription;

  useEffect(() => {
    if (token) {
      props.getSurveyList();
      props.getUserData(localStorage.getItem("userKey"));
    }
  }, []);

  useEffect(() => {
    handleMenuClose();
  }, [isSmallScreen]);



  const handleLogout = async () => {

    localStorage.clear();
    sessionStorage.clear();

    setTimeout(() => {
      window.open("http://japa.health/wordpress-dev/?user_logout=true");
    }, 200);

  };


  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  const surveyIdItem = props.user?.surveyIdList[props.user?.surveyIdList?.length - 1]
  const getRedirectPath = async (event, link) => {
    event.preventDefault();
    if (link.id === 'home') {
      if (surveyIdItem.s_IsSurveyCompleted === 1) {
        localStorage.setItem("progressDone", true);
      } else {
        localStorage.removeItem("progressDone");
        localStorage.removeItem("detailDone");
      }

      if (
        surveyIdItem.Height &&
        surveyIdItem.Weight &&
        surveyIdItem.ZipCode &&
        surveyIdItem.Age &&
        surveyIdItem.Gender
      ) {
        if (surveyIdItem.Score === 0) {
          localStorage.removeItem("detailDone");
        } else {
          localStorage.setItem("detailDone", true);
        }
      } else {
        localStorage.removeItem("detailDone");
      }

      if (localStorage.getItem("progressDone") === null) {
        navigate(`/progress`);
      } else if (localStorage.getItem("detailDone") === null) {
        navigate("/profiledetail");
      } else {
        navigate(`/result`);
      }
      localStorage.setItem("assesmentKey", surveyIdItem.SurveyId);
      dispatch(setUserData({ surveyId: surveyIdItem.SurveyId }));
    } else {
      navigate(link.pathname)
    }
  };

  const handleOrderLabsClick = () => {
    setOpenModal(true)
  };

  const handleSendInviteForLabs = async () => {
    setLoading(true)
    try {
      const res = await sendInviteForLabsApi();
      setOpenModal(false)
      setOpenConfirmModal(true)

    } catch (error) {
      setOpenSnackBar({
        open: true,
        message: error?.message || "Something went wrong.",
        severity: 'error'
      });
    } finally {
      setLoading(false)
    }
  }


  return (
    <>
      <CenterLoading open={loading} />
      <Box
        sx={{
          flexGrow: 1,
          background: "white",
          boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.1)",
          position: "sticky",
          top: 0,
          zIndex: 1100,
        }}
      >
        <Container
          sx={{
            backgroundColor: "#fefafa",
            color: "#0e1b1d",
            boxShadow: "none",
            py: "5px",
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            {/* Logo Section */}
            <Box className="logo-container">
              <Link to="/">
                <img src={Logo} alt="Japa Logo" style={{ height: '100%', width: '100%' }} />
              </Link>
            </Box>

            {/* Desktop Navigation */}
            {token ? (
              <Box
                className="header__links"
                sx={{
                  display: isSmallScreen ? "none" : "flex",
                  alignItems: "center",
                  gap: "20px",
                }}
              >
                <Box sx={{ fontWeight: 600, cursor: 'pointer' }} onClick={handleOrderLabsClick} >
                  Order Labs
                </Box>
                {headerLinks
                  .filter((link) =>
                    (link.id !== "courses" || isSubscription) &&
                    (link.id !== "home" || props.user?.surveyIdList?.length > 0)
                  )
                  .map((link) => (
                    <NavLink
                      key={link.id}
                      to={link.pathname}
                      onClick={(event) => getRedirectPath(event, link)}
                      className={({ isActive }) => {
                        const isLastSurvey =
                          surveyIdItem?.SurveyId === parseInt(localStorage.getItem('assesmentKey'));

                        return (location.pathname !== '/result' && isActive) || (link.id === "home" && isLastSurvey && ["/progress", "/result", "/profiledetail"].includes(location.pathname))
                          ? "active-link"
                          : "";
                      }}
                      style={{ textDecoration: "none", fontSize: "16px" }}
                    >
                      {link.label}
                    </NavLink>
                  ))}
                <Box className="logout" sx={{ cursor: "pointer" }} onClick={handleLogout}>
                  Logout
                </Box>
                <Box className="header__welcomeuser">
                  <Box sx={{ color: "inherit" }}>Welcome</Box>
                  <Box sx={{ textAlign: "center", marginTop: "-5px" }}>
                    {props.user.firstName}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box className="header__links" sx={{ display: "flex", gap: "20px" }}>
                <NavLink to="/signin" className={({ isActive }) => (isActive ? "active-link" : "")}>
                  Sign in
                </NavLink>
                <NavLink to="/signup" className={({ isActive }) => (isActive ? "active-link" : "")}>
                  Sign Up
                </NavLink>
              </Box>
            )}

            {/* Mobile Menu Icon */}
            {token && isSmallScreen && (
              <Box className="header__welcomeuser">
                <Typography sx={{ color: "inherit", textAlign: "center" }}>Welcome</Typography>
                <Typography
                  onClick={handleMenuOpen}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    cursor: "pointer",
                    marginTop: "-5px",
                  }}
                >
                  <span>{props.user?.firstName ? props.user?.firstName : ""}</span>
                  <ExpandMore sx={{ fontSize: "20px" }} />
                </Typography>
              </Box>
            )}
          </Toolbar>
        </Container>

        {/* Mobile Menu */}
        <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
          <MenuItem onClick={handleOrderLabsClick} sx={{ cursor: "pointer" }}>
            Order Labs
          </MenuItem>
          {headerLinks
            .filter((link) =>
              (link.id !== "courses" || isSubscription) &&
              (link.id !== "home" || props.user?.surveyIdList?.length > 0)
            )
            .map((link) => {
              const currLocation = location.pathname === link.pathname;
              const isLastSurvey =
                surveyIdItem?.SurveyId === parseInt(localStorage.getItem('assesmentKey'));

              const isActive = (location.pathname !== '/result' && currLocation) || (link.id === "home" && isLastSurvey && ["/progress", "/result", "/profiledetail"].includes(location.pathname))
                ? "active-link"
                : "";

              return (
                <MenuItem
                  key={link.id}
                  onClick={handleMenuClose}
                  className={isActive ? "menuItem__active-link" : ""}
                >
                  <NavLink
                    to={link.pathname}
                    onClick={(event) => getRedirectPath(event, link)}
                    style={{ textDecoration: "none", color: "inherit", width: "100%" }}
                  >
                    {link.label}
                  </NavLink>
                </MenuItem>
              )
            })}
          <Divider />
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>


      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <Box sx={modalStyle} width={"45%"} maxWidth={"600px"} minWidth={"350px"}>
          <Box className="close-pop-wrap">
            <Box className="Heading">
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#fff" }}
              >
                {isSubscription
                  ? "🔬 Exclusive Deal: 43% OFF on Medical Lab Tests!"
                  : "Join & Unlock Discounted Lab Tests 🚀"
                }
              </Typography>
            </Box>
            <CloseIcon onClick={() => setOpenModal(false)} className="CloseImg" />
          </Box>

          {isSubscription ? (
            <Box className="ContentWrapper">
              <Box
                sx={{
                  background: "#f4f4f4",
                  padding: "16px",
                  borderRadius: "8px",
                  textAlign: "center"
                }}
              >
                <Typography variant="h6" fontWeight="bold" color="#d32f2f">
                  🎉 Unlock Your 43% Member Discount! 🎉
                </Typography>
                <Typography fontSize="16px" mt={1}>
                  As a valued member of <strong>Japa Health</strong>, you get access to a
                  <span style={{ color: "#d32f2f", fontWeight: "bold" }}> premium lab test bundle </span>
                  valued at <span style={{ fontWeight: "bold", color: "#f57c00" }}>$707 </span>
                  for just <span style={{ fontWeight: "bold", color: "#388e3c" }}> $399</span>
                </Typography>
              </Box>

              <Typography fontWeight="bold" mt={2}>Ready to order your labs?</Typography>
              <Typography>Click "OK" to confirm, and we’ll email you a personalized link to place your order.</Typography>
              <Typography
                mt={1}
                fontWeight="bold"
                fontSize="18px"
                color="#d32f2f"
                textAlign="center"
              >
                🚀 <span style={{ textDecoration: "underline" }}>Take control of your health today </span>
                with this <span style={{ fontStyle: "italic", color: "#f57c00" }}>exclusive deal!</span> 🏥
              </Typography>


              <Box className="modal-actions" display="flex" justifyContent="end" mt={2} marginRight={2}>
                <Button variant="contained" color="primary" sx={{
                  marginRight: 2,
                  background: '#E29C2E',
                  '&:hover': {
                    background: '#E29C2E'
                  }
                }} onClick={handleSendInviteForLabs}>
                  OK
                </Button>
                <Button variant="outlined" color="secondary" onClick={() => setOpenModal(false)} >
                  Cancel
                </Button>
              </Box>
            </Box>
          ) : (
            <Box>
              <Box sx={{
                background: "#f4f4f4",
                padding: "0px 15px",
                borderRadius: "8px",
                textAlign: "left",
                boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                overflow: 'auto',
                maxHeight: "340px",
              }}>
                <Typography variant="h6" fontWeight="bold" color="#333">
                  Become a Japa Health member and you get:
                </Typography>

                <Box mt={1}>
                  <Typography fontSize="15px" color="#333">
                    ✅ <b>43% Off Lab Testing</b> – Save $308 on 94 Biomarkers!!
                  </Typography>

                  <Typography fontSize="15px" color="#333" mt={1}>
                    ✅ <b>Personalized Journey</b> – Recommendations based on your assessment
                  </Typography>

                  <Typography fontSize="15px" color="#333" mt={1}>
                    ✅ <b>Goal Setting & Progress Tracking</b> – Eliminate the overwhelm
                  </Typography>

                  <Typography fontSize="15px" color="#333" mt={1}>
                    ✅ <b>Quantum Health Lessons</b> – Decentralized education to optimize your life force
                  </Typography>

                  <Typography fontSize="15px" color="#333" mt={1}>
                    ✅ <b>Full-Length Courses</b> – Including how to interpret your labs and take control of your health
                  </Typography>

                  <Typography fontSize="15px" color="#333" mt={1}>
                    ✅ <b>Movement & Meditation</b> – Yoga, strength training, mobility, and meditation classes
                  </Typography>

                  <Typography fontSize="15px" color="#333" mt={1}>
                    ✅ <b>Exclusive Product Discounts</b> – We recommend the best brands
                  </Typography>

                  <Typography fontSize="15px" color="#333" mt={1}>
                    ✅ <b>Anti-Inflammatory Recipes</b> – Learn how to eat, shop, and cook
                  </Typography>

                  <Typography fontSize="15px" color="#333" mt={1}>
                    ✅ <b>Live Monthly Q&As with Experts</b> – Get answers from world-renowned doctors
                  </Typography>
                </Box>

                <Typography fontSize="16px" color="#d32f2f">
                  🔒 <b>Empower your healing and optimization journey here!</b>
                </Typography>
              </Box>

              <Box display="flex" justifyContent="center" mt={2}>
                <Button
                  variant="contained"
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    // padding: "8px 20px",
                    background: '#E29C2E',
                    '&:hover': {
                      background: '#C07D1A'
                    }
                  }}
                  onClick={() => {
                    navigate("/plans")
                    setOpenModal(false)
                  }}
                >
                  JOIN NOW
                </Button>
              </Box>
            </Box>
          )}
        </Box>
      </Modal >


      <Modal open={openConfirmModal} onClose={() => setOpenConfirmModal(false)}>
        <Box sx={modalStyle} width={"fit-content"} maxWidth={"600px"} minWidth={"350px"}>
          <Box className="close-pop-wrap">
            <Box className="Heading">
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "#fff", letterSpacing: "1px" }}
              >
                Confirmation
              </Typography>
            </Box>
            <CloseIcon onClick={() => setOpenConfirmModal(false)} className="CloseImg" />
          </Box>
          <Box className="ContentWrapper">
            <Typography>
              🎉 Awesome! Check the email you registered with for instructions. 📩
            </Typography>
          </Box>
        </Box>
      </Modal>


      <SnackBar
        open={openSnackBar?.open}
        message={openSnackBar?.message}
        severity={openSnackBar?.severity}
        handleCloseSnackBar={() => {
          setOpenSnackBar({
            open: false,
            message: '',
            severity: ''
          })
        }}
      />
    </>
  );
};

const actionCreators = { getSurveyList, getUserData };

export default connect(({ user }) => ({ user }), actionCreators)(Header);


const modalStyle = {
  overflow: "hidden",
  position: "relative",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxHeight: "90vh",
  backgroundColor: "white",
  boxShadow: 24,
  pb: 2,
  borderRadius: "8px",
  ".close-pop-wrap": {
    top: "0px",
    right: "0px",
    position: "sticky",
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#112225",
    img: {
      width: "24px",
      height: "24px",
    },
  },
  ".Heading": {
    display: "flex",
    alignItems: "center",
    h2: {
      mb: 0,
      color: "#fff",
    },
    svg: {
      mr: 1,
      width: "20px",
      height: "20px",
    },
  },
  ".CloseImg": {
    opacity: 1,
    borderRadius: "50%",
    p: "2px",
    border: "1px solid #fff",
    fontSize: "0.7rem",
    path: {
      fill: "#fff",
    },
  },
  ".ContentWrapper": {
    p: 2,
  },
};